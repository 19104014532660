import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PaymentForm from './PaymentForm';
import paymentService from '../services/paymentService';

const CheckoutPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get('prod'); // The product ID passed from the other components

  const [productDetails, setProductDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);

  // Fetch product details when the page loads
  useEffect(() => {
    const fetchProductDetails = async () => {
      if (productId) {
        try {
          const data = await paymentService.fetchProduct(productId);
          setProductDetails(data); // Save the product details to state
        } catch (error) {
          setError('Failed to load product details.');
        } finally {
          setLoading(false);
        }
      } else {
        setError('No product selected.');
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handlePaymentSuccess = () => {
    setStatusMessage("Payment successful!");
  };

  const handlePaymentError = (errorMessage: string) => {
    setStatusMessage(`Payment error: ${errorMessage}`);
  };

  // Show a loading message while fetching product details
  if (loading) {
    return (
      <div className="text-center py-10">
        <p>Loading product details...</p>
      </div>
    );
  }

  // Show an error message if there's an issue
  if (error) {
    return (
      <div className="text-center py-10">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div>
      <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16">
        <div className="px-4 mx-auto max-w-8xl lg:px-4 lg:text-center">
          <h1 className="mb-16 text-4xl font-bold tracking-tight text-gray-900 lg:font-extrabold lg:text-6xl lg:leading-none dark:text-white lg:text-center xl:px-36 lg:mb-7">
            Checkout
          </h1>

          {/* Display product details */}
          {productDetails && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold">{productDetails.name || 'Product Name'}</h2>
              <p className="text-gray-700 dark:text-gray-300">{productDetails.description || 'No description available'}</p>
              <p className="text-lg font-semibold">
                {productDetails.currency?.toUpperCase() || 'USD'} {productDetails.price?.toFixed(2) || '0.00'} / {productDetails.interval || 'N/A'}
              </p>
            </div>
          )}

          {statusMessage && (
            <div className={`mb-4 p-4 rounded-md ${statusMessage.startsWith('Payment error') ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
              {statusMessage}
            </div>
          )}

          {/* Payment Form */}
          <PaymentForm
            productId={productId || ''}
            amount={productDetails?.price || 0}
            onSuccessfulPayment={handlePaymentSuccess}
            onPaymentError={handlePaymentError}
          />
        </div>
      </section>
    </div>
  );
};

export default CheckoutPage;
